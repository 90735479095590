




















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { OfferPayload } from '@/sparkmembers/views/ProductSetup/components/OrderBumpConfig/types';
import { OfferType } from '@/types/offers';

@Component({
  components: {
    MButton: () => import('@/shared/components/MButton.vue'),
    OrderBumpSelectOfferModal: () => import('./OrderBumpSelectOfferModal.vue'),
  },
})
export default class OrderBumpSelectOffer extends Vue {
  selectedOffer: OfferPayload = {
    id: null,
    title: '',
    school_product_id: null,
    kind: OfferType.COMMON,
  };

  @Prop() preSelectedOffer: OfferPayload;
  @Prop({ required: true }) availableOffers: Array<OfferPayload>;

  get hasSelectedOffer() {
    return Boolean(this.selectedOffer.id);
  }

  get offerDisplayText() {
    return this.hasSelectedOffer ? this.selectedOffer.title : 'A oferta será apresentada aqui';
  }

  get hasOnlyOneOffer() {
    return this.availableOffers.length === 1;
  }

  openSelectOfferModal() {
    this.$bvModal.show('order-bump-select-offer-modal');
  }

  setSelectedOffer(offer) {
    this.selectedOffer = offer;
    this.$emit('select', this.selectedOffer);
  }

  imageURL(kind) {
    const image = {
      free: 'free',
      common: 'default',
      subscription: 'subscription',
    };

    return require(`@/assets/images/SparkMembers/OffersProduct/${image[kind]}.svg`);
  }

  @Watch('preSelectedOffer', { immediate: true })
  onChangePreSelectedOffer(offer) {
    if (offer) this.setSelectedOffer(offer);
  }

  @Watch('hasOnlyOneOffer', { immediate: true })
  onChangeHasOnlyOneOffer(hasOnlyOne) {
    if (hasOnlyOne) this.setSelectedOffer(this.availableOffers[0]);
  }
}
